import React from 'react';
import ReceiptSVG from '../../components/receiptSVG';
import gsap from "gsap";
import { useRef, useEffect } from 'react';
import ILEIntroAnimation from '../../components/ItiliteExp/IntroAnimation';
import Layout from '../../components/layout';

import { ScrollTrigger } from "gsap/ScrollTrigger";
import FigmaAnimation from '../../components/ItiliteExp/FigmaAnimation';
import MansoryBlocks from '../../components/ItiliteExp/MansoryBlocks';
import DesignSys from '../../components/ItiliteExp/DesignSys';
import ScaningAnimation from '../../components/ItiliteExp/ScaningAnimation';
// import { SteppedEase } from 'gsap/all';
import { TimelineMax } from 'gsap/gsap-core';
import { SteppedEase } from 'gsap/all';
import GatsbyImage from 'gatsby-image';
import { Noise } from '../../components/noise';

const SkillBoxSkills = [
    'SQL', 'React', 'Java Spring Framework', 'Python'
]
const SkillBox = () => {

    const tl = useRef();
    const blockRef = useRef();

    const blocksArrayRef = useRef();
    blocksArrayRef.current = [];
    const addToblocksArrayRef = (el) => {
        if (el && !blocksArrayRef.current.includes(el)) {
            blocksArrayRef.current.push(el);
        }
    };

  
    useEffect(() => {
        //tl.current = new TimelineMax({  });

        gsap.from(blocksArrayRef.current, {
            opacity:0,
            x:-2,
            stagger: 0.1,
            duration:0.2,
            ease:'expo.inOut',
        }, "+=5")

        // tl.current.st
        //tl.current.staggerFrom(blockRef.current, 1, { y: -100, opacity: 0 }, { y: 0, opacity: 1, ease: SteppedEase.config(1) });
    }, []);


    return (
        <div style={{ width:'360px' }} className=' bg-white rounded-md border-default border-gray-200 shadow-md'>
           {SkillBoxSkills.map((skill, sindex) => {
               const maxNumber = Math.floor(Math.random() * 10) + 1 ;
               
               return(
                <div className={`flex w-full items-center justify-between px-3 py-2  border-b-default border-gray-200`}>
                    <span className='text-sm text-black font-normalstr'>{skill}</span>
                    <span className='grid grid-cols-10 gap-1'>
                        { [1,2,3,4,5,6,7,8,9,10].map((index) => {
                            return <div ref={addToblocksArrayRef} className={`w-2 h-4 rounded-sm ${index > maxNumber ? 'bg-gray-400' : 'bg-indigo-700'} bg-gray-400`}>{}</div>
                        }) }
                    </span>
                </div>
           )})} 
        </div>
    )
}



const HeadingContainer = () => {

    const introTextAnimationBlock = useRef();
    const introTextParaAnimationBlock = useRef();


    const imageContainerRef = useRef();
    const backColorRef=useRef();
    const tl = useRef();
    const tl2 = useRef();
    const codeBlocksRef=useRef();
    useEffect(() => {
      
        tl.current = new TimelineMax();

      

        // tl.current.from(backColorRef.current, {
        //     width:0,
        //     ease: "power3.inOut",
        //     x:-30
        // });

        tl.current.from(imageContainerRef.current,  {
            width:0,
            ease: "power3.inOut",
            x:-70,
            duration:1
        });

        tl.current.fromTo(introTextAnimationBlock.current, {
            opacity:0,
            y:5
        }, {
            opacity:1,
            y:0,
            ease: "power3.inOut",
            stagger: 0.1,
            
        });


        tl.current.fromTo(introTextParaAnimationBlock.current, {
            opacity:0,
            y:5
        }, {
            opacity:1,
            y:0,
            ease: "power3.inOut",
            stagger: 0.1,
            
        });

        tl.current.from(codeBlocksRef.current, {
            x:-20,
            opacity:0,
            ease: "power3.inOut",
            duration:0.6
            // duration:1.2
        })


        tl2.current = new TimelineMax({
            repeat: true
        })

        
        
        tl2.current.fromTo(codeBlocksRef.current, {
            y:10
        }, {
            y:-10
        })
        
        
      return () => {
        //TL
      }
    }, [])


    
    return (
                <div style={{ maxWidth: maxWidth }} className='flex text-white mx-auto px-4 py-12'>
                    <div className='w-5/12  realtive px-12'>
                        
                    <span ref={backColorRef} style={{ width: '620px', height: '420px', zIndex: '0', opacity:0.1, top:'142px', backgroundColor:'#78350F' }} className="absolute  top-0 "></span>
                    <div ref={codeBlocksRef}  className='absolute hello' style={{ top:'362px', zIndex:5, left:'230px' }}><SkillBox /></div>
                    <span className='h-42 w-42 rounded-full bg-orange-100 absolute' style={{ top:'100px', left:'20px', width:'160px', height:'160px', zIndex:2, '-webkit-backdrop-filter':'blur(4px)', background:'rgba(255,255,255,0.6)'}}></span>



                        <div  className='w-full flex'>
                            <div ref={imageContainerRef} className='relative justify-center items-center mx-auto overflow-hidden ' style={{ width:'400px', height:'420px' }}>
                                <img style={{ width:'400px', height:'420px', zIndex:0 }} className=' transform scale-100 duration-200 ease-out  object-cover shadow-2xl ' src="/client/wecp/christina-wocintechchat-com-unsplash.jpg" />
                            </div>
                        </div>
{/* 
                    

                        <div style={{ left:'100px', }} className='absolute top-0 mt-24 z-0'>
                            <CodeLines />
                        </div> */}
                    </div>
                    <div className='w-6/12  py-24 lg:pr-36 md:pr-12 pl-16'>
                        <h1 ref={introTextAnimationBlock} className='font-mediumstr text-3xl'>Making coding assessments easy for enterprises with design and simplicity</h1>
                        <p ref={introTextParaAnimationBlock} className='font-normalstr text-lg text-gray-700 mt-4 leading-relaxed'>An experience centeric coding assesment platform for fast tech companies to hire productive employees faster.</p>

                        

                        
                        


                    
                    </div>
                </div>
    )
}



const CodeLines = () => {
    const codeEachLine = useRef(null);
    const timelinetl1 = useRef(null)
    codeEachLine.current = [];

    const addTocodeEachLineArrayRef = (el) => {
        if (el && !codeEachLine.current.includes(el)) {
            codeEachLine.current.push(el);
        }
    };




    useEffect(() => {
        timelinetl1.current = new TimelineMax({
            paused: true,
            repeat: 1
        });


        timelinetl1.current.fromTo(codeEachLine.current, 0.5, {
            "border-right-color": "rgba(255,255,255,0.75)"
          }, {
            "border-right-color": "rgba(255,255,255,0)",
            repeat: -1,
            ease:  SteppedEase.config(49)
          }, 0);


        timelinetl1.current.fromTo(codeEachLine.current, 3, {
            width: "0",
          }, {
            width: "33.18em", /* same as CSS .line-1 width */
            "border-right-color": "rgba(255,255,255,0)",
            ease:  SteppedEase.config(49),
            stagger: {
                amount: 13
            }
          }, 0)




          timelinetl1.current.play();
      return () => {
        //do nothing
      }
    }, [])
    
    return (
                        <code className='font-mono text-blue-800 shadow-md'>
                                <div ref={addTocodeEachLineArrayRef} className=' text-gray-900 mb-3 overflow-hidden whitespace-nowrap  border-gray-200'>// Type or paste the code you want to highlight below </div>
                      
                        <div ref={addTocodeEachLineArrayRef} className='text-gray-900 mb-3 overflow-hidden whitespace-nowrap'>  // or select a text node and run the plugin</div>

                            <div ref={addTocodeEachLineArrayRef} className='text-blue-800 mb-2 overflow-hidden whitespace-nowrap'>import syntaxHighlight from 'syntax-highlight'</div>
                            <div ref={addTocodeEachLineArrayRef} className='text-blue-800 mb-2 overflow-hidden whitespace-nowrap'>const codeBlock = <span className='text-green-900'>document</span>.querySelector(<span className='text-orange-800'>"#code"</span>)</div>
                            <div className='overflow-hidden whitespace-nowrap' ref={addTocodeEachLineArrayRef}>function highlight(<span className='text-orange-800 overflow-hidden whitespace-nowrap'>code</span>) {`{`} </div>
                            <div ref={addTocodeEachLineArrayRef} className='ml-4 overflow-hidden whitespace-nowrap'><span className='text-gray-600 overflow-hidden whitespace-nowrap'>return</span> syntaxHighlight(<span className='text-orange-800'>code</span>)</div>
                                <div className='overflow-hidden whitespace-nowrap' ref={addTocodeEachLineArrayRef}>{`}`}</div>
                            <br /><br />

                            <div ref={addTocodeEachLineArrayRef} className='text-blue-800 overflow-hidden whitespace-nowrap'>export const <span className='text-green-900'>Highlighter</span> = () {`=>`} {`{`}</div>
                                <div ref={addTocodeEachLineArrayRef} className='ml-4 overflow-hidden whitespace-nowrap'> return(</div>
                                <div ref={addTocodeEachLineArrayRef} className='ml-8 overflow-hidden whitespace-nowrap'>{` <button onClick={`}<span className='text-orange-800'>highlight</span>{`}>`}<span className='text-gray-500'>Highlight</span>{`</button>`}</div>
                            <div ref={addTocodeEachLineArrayRef} className='ml-4 overflow-hidden whitespace-nowrap'>{`)}`}</div>    
                        </code>
    )
}




const maxWidth = '1880px';


const ItiliteExpense = () => {

    const tl = useRef();
    const tl2 = useRef();
    const figmaTl = useRef();
    const introAnimationBlock = useRef();
    const introTextParaAnimationBlock = useRef();
    const workTypeArrayRef = useRef();
    const largeIphoneRef = useRef();
    workTypeArrayRef.current = [];

    const addToworkTypeArrayRef = (el) => {
        if (el && !workTypeArrayRef.current.includes(el)) {
            workTypeArrayRef.current.push(el);
        }
    };



    useEffect(() => {


        // if (typeof window !== `undefined`) {
        //     gsap.registerPlugin(ScrollTrigger);
        //     gsap.core.globals("ScrollTrigger", ScrollTrigger);

        // }
        
        tl.current = gsap.timeline({
            paused: true,
            scrollTrigger: {
                markers: false,
                trigger: introAnimationBlock.current,
                scrub: 1,
                start: '+=20'
            }
        });


        tl2.current = gsap.timeline({
      
        });



        tl2.current.from([workTypeArrayRef.current], {
            opacity:0,
            bottom:-20,
            stagger: 0.1,
            duration: 1,
            // scrollTrigger: {
            //     trigger:workTypeArrayRef.current[0],
            //     markers:false
            // }
        }, '+=3')


        return () => {
            //do nothing
        }
    }, [])

    // const childSplit = new SplitText(introTextAnimationBlock.current, {
    //     type: "lines",
    //     linesClass: "overflow-hidden"
    //   });
    //   const parentSplit = new SplitText(introTextAnimationBlock.current, {
    //     // type: "lines",
    //     linesClass: "hidden"
    //   });


    // useEffect(() => {
      
    //     tl.current = new TimelineMax();

    //     tl.current.fromTo(introTextAnimationBlock.current, {
    //         opacity:0,
    //         x:-3
    //     }, {
    //         opacity:1,
    //         x:0,
    //         ease: "power3.inOut",
    //         stagger: 0.1,
            
    //     }, "+=1");


    //     tl.current.fromTo(introTextParaAnimationBlock.current, {
    //         opacity:0,
    //         x:-3
    //     }, {
    //         opacity:1,
    //         x:0,
    //         ease: "power3.inOut",
    //         stagger: 0.1,
            
    //     });

        
        
    //   return () => {
    //     //TL
    //   }
    // }, [])
    


    let mouseHoverRef = useRef({
        x:1
      });


    return (
        <Layout mouseHoverState={mouseHoverRef.current}>
            <div ref={introAnimationBlock} className="bg-gray-900">
                
                <Noise />
                <HeadingContainer />
                {/* <div style={{ maxWidth: maxWidth }} className='flex text-white mx-auto px-4 py-12'>
                    <div className='w-5/12  realtive px-12'>
                        
                    <span style={{ width: '620px', height: '420px', zIndex: '0', opacity:0.2, top:'142px', backgroundColor:'#78350F' }} className="absolute  top-0 "></span>
                    <div className='absolute hello' style={{ top:'462px', zIndex:5, left:'230px' }}><SkillBox /></div>


                        <div  className='w-full flex'>
                            <div className='relative justify-center items-center mx-auto overflow-hidden ' style={{ width:'400px', height:'620px' }}>
                                <img style={{ width:'400px', height:'620px', zIndex:0 }} className=' transform scale-100 duration-200 ease-out  object-cover shadow-2xl ' src="/client/wecp/christina-wocintechchat-com-unsplash.jpg" />
                            </div>
                        </div>

                    

                        <div style={{ left:'100px', }} className='absolute top-0 mt-24 z-0'>
                            <CodeLines />
x
                        </div>
                    </div>
                    <div className='w-7/12  py-12 lg:pr-36 md:pr-12 pl-16'>
                        <h1 ref={introTextAnimationBlock} className='font-mediumstr text-3xl'>Making coding assessments easy for enterprises with design and simplicity</h1>
                        <p ref={introTextParaAnimationBlock} className='font-normalstr text-base text-gray-600 mt-2 leading-relaxed'>An experience centeric coding assesment platform for fast tech companies to hire productive employees faster.</p>
                    </div>
                </div> */}


                <div  className="max-w-5xl mx-auto mt-20 pb-10 flex flex-col md:flex-row justify-between px-8 md:px-0">


                        <div ref={addToworkTypeArrayRef} className="mb-8">
                            <div className="text-sm text-gray-300 mb-3">
                                Client Website
                            </div>
                            <a href="#" className="text-gray-500 text-base">
                               wecreateproblems.com
                            </a>
                        </div>


                        <div ref={addToworkTypeArrayRef} className="mb-8">
                            <div className="text-sm text-gray-300 mb-3">
                                Deliverables
                            </div>
                            <div className="text-gray-500 flex flex-wrap text-sm">
                                <span className=" font-light rounded-lg px-2 py-2 border-gray-800 border-2 mr-3 bg-black shadow-default">Experience Design</span>
                                <span className=" font-light mt-2 md:mt-0 rounded-lg px-2 py-2 mr-3 border-gray-800 border-2 bg-black shadow-default">Interface Design</span>
                            </div>
                        </div>

                        <div ref={addToworkTypeArrayRef} className="mb-8">
                            <div className="text-sm text-gray-300 mb-3">
                                Tools used
                            </div>
                            <div className="text-gray-500  text-sm flex flex-wrap">
                                <span className=" font-light rounded-lg px-2 py-2 border-gray-800 border-2 mr-3 bg-black shadow-default">Figma</span>
                                <span className=" font-light mt-2 md:mt-0 rounded-lg px-2 py-2 mr-3 border-gray-800 border-2 bg-black shadow-default">Miro</span>
                            </div>
                        </div>


                        <div ref={addToworkTypeArrayRef} className="mb-8">
                            <div className="text-sm text-gray-300 mb-3">
                                Time Duration
                            </div>
                            <div className="text-gray-500">
                               8 months
                            </div>
                        </div>



                    </div>
            
            </div>

            <div className=' hidden text-white mx-auto px-8 md:px-0 py-12 max-w-5xl'>
                   <h2 className='text-3xl font-mediumstr'>About We Create Problems</h2>
                   <p className='text-base text-gray-500 font-normalstr mt-2'>Doesnt sound like a name that you could put for a product right? but the name convey what they do the best. 
WeCreateProblems.com helps companies find the best candidate to hire by assessing coding skills. When we met, WECP 
has assessed more than 100K developers from various enterprise parterns with best in class questions and tool. Abhisehk, Mohit and his team created platform that works perfectly for HR teams at top enterprise companies like Infosys, Mindtree etc.</p>
            </div>

            <div className='mx-auto px-8 py-24 text-center'>
                <small className='text-gray-700 text-base'>Case study in making</small>
            </div>



        
         
        </Layout>

    )
}


export default ItiliteExpense;