import React from 'react'

export const Noise = () => {
  return (
    <div className="h-screen w-screen absolute">
        <svg 
        viewBox="0 0 200 200"
        xmlns='http://www.w3.org/2000/svg'>

        <filter id='noiseFilter'>
         
        </filter>

        <rect
            width='100%' 
            height='100%' 
            filter='url(#noiseFilter)' />
        </svg>
</div>
  )
}
